@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--dark);
}

h1 {
  font-size: 5rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3.5rem;
}
h4 {
  font-size: 3rem;
}
h5 {
  font-size: 2.5rem;
}
h6 {
  font-size: 2rem;
}
p {
  font: 1.6rem;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

/* Theme Class Configuartion */

:root {
  --dark: #222;
  --light: #fafafa;
  --uc--light: #fafafa;
  --uc--dark: #222;
  --grey: #808083;
  --b2c: #c10230;
  --b2b: #558b6e;
  --ppl: #ff6600;
  --edu: #0033cc;
}

.dark {
  --dark: #fafafa;
  --light: #222;
}

.dark {
  background-color: #fafafa;
  background-color: var(--light);
}

.dark *,
.light * {
  transition: 0.3s;
}

.light {
  --dark: #222;
  --light: #fafafa;
}

.container {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 3rem;
}

.dark-mode-button {
  position: fixed;
  top: 30%;
  right: 0;
  border: none;
  background-color: #222;
  background-color: var(--dark);
  color: #fafafa;
  color: var(--light);
  border: 1px solid #fafafa;
  border: 1px solid var(--light);
  padding: 1rem 2rem;
  -webkit-transform: rotate(90deg) translateY(15px);
          transform: rotate(90deg) translateY(15px);
  -webkit-transform-origin: right;
          transform-origin: right;
  z-index: 999999;
}

/* Grid Layout Construction */

.grid {
  display: grid;
  text-align: center;
  grid-gap: 3rem;
}

.grid8 {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.grid6 {
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}

.grid5 {
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}

.grid4 {
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
}

.grid3 {
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
}

.grid2 {
  grid-template-columns: repeat(auto-fit, minmax(36rem, 1fr));
}

.grid8 div,
.grid6 div,
.grid5 div,
.grid4 div,
.grid3 div,
.grid2 div,
.flex div {
  background-color: aqua;
  border: 2px solid #222;
  border: 2px solid var(--dark);
  min-height: 150px;
  vertical-align: center;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
}

.flex8 div {
  flex-grow: 1;
  min-width: 12rem;
}

.flex6 div {
  flex-grow: 1;
  min-width: 12rem;
}

.flex5 div {
  flex-grow: 1;
  min-width: 12rem;
}

@media only screen and (max-width: 760px) {
  .App {
    overflow-x: hidden;
  }
  .dark-mode-button {
    top: 80%;
  }
}

.header {
  box-shadow: 0 0 10px rgb(207, 207, 207);
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--light);
  color: var(--dark);
  top: 0;
  z-index: 999;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
  padding: 0 3rem;
  width: 128rem;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.dark .header {
  box-shadow: 0 0 10px rgb(0, 0, 0);
}

.logo {
  width: 24rem;
}

.dark .st0 {
  fill: var(--dark);
}

.st1 {
  fill: #808083;
}

.the-practice-animation {
  -webkit-animation: left-right 1s;
          animation: left-right 1s;
}

.nav {
  display: flex;
}

.nav a {
  text-decoration: none;
  position: relative;
  font-size: 2rem;
  color: var(--dark);
  padding: 2.5rem 2rem;
}

.nav a:hover::after,
.nav .active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--dark);
  opacity: 0;
  bottom: 0;
  left: 0;
}

.nav a:hover::after,
.nav .active::after {
  opacity: 1;
}

.menu-icon {
  position: absolute;
  right: 4rem;
  top: 2rem;
  display: none;
}

.menu-icon svg {
  font-size: 3rem;
  transition: 0.3s;
  position: absolute;
  background-color: var(--light);
}

#XMLID_27_,
#XMLID_24_ {
  -webkit-animation: springBoard 4s;
          animation: springBoard 4s;
}

@-webkit-keyframes springBoard {
  0% {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scaleX(6.5);
            transform: scaleX(6.5);
  }
}

@keyframes springBoard {
  0% {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scaleX(6.5);
            transform: scaleX(6.5);
  }
}

@-webkit-keyframes left-right {
  0% {
    -webkit-transform: translateX(40rem);
            transform: translateX(40rem);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes left-right {
  0% {
    -webkit-transform: translateX(40rem);
            transform: translateX(40rem);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@media only screen and (max-width: 760px) {
  .header {
    max-width: 100%;
    padding: 1rem 0;
  }
  .logo {
    width: 18rem;
  }
  .menu-icon {
    display: block;
  }
  .nav {
    flex-direction: column;
    grid-gap: 0rem;
    gap: 0rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 100vh;
    width: 50%;
    padding: 2rem;
    margin-top: 66px;
    transition: 0.3s;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .dark .nav {
    background-color: var(--light);
    color: var(--dark);
  }
  .active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    overflow-x: visible;
  }
  .nav a {
    border-bottom: 1px solid rgb(206, 206, 206);
    padding: 2rem 0;
  }
}

.footer {
  background: var(--uc--dark);
  color: var(--uc--light);
  padding: 5rem 0;
}

.footer * {
  color: var(--uc--light);
}

.dark .footer {
  border-top: 1px solid var(--grey);
}

.footerGrid {
  display: grid;
  grid-template-columns: 3fr 2fr 4fr;
  grid-gap: 5rem;
}

.footerGridItem3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.footerGridItem3 img {
  width: 26rem;
}

.footerGridItem3 img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.footerGridItem1 img {
  padding-right: 6px;
}

@media only screen and (max-width: 760px) {
  .footerGrid {
    grid-template-columns: 1fr;
  }
}

.full-width {
  width: 100%;
}

.banner {
  padding: 10vh 30px;
}

