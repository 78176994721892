@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--dark);
}

h1 {
  font-size: 5rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3.5rem;
}
h4 {
  font-size: 3rem;
}
h5 {
  font-size: 2.5rem;
}
h6 {
  font-size: 2rem;
}
p {
  font: 1.6rem;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}
