/* Theme Class Configuartion */

:root {
  --dark: #222;
  --light: #fafafa;
  --uc--light: #fafafa;
  --uc--dark: #222;
  --grey: #808083;
  --b2c: #c10230;
  --b2b: #558b6e;
  --ppl: #ff6600;
  --edu: #0033cc;
}

.dark {
  --dark: #fafafa;
  --light: #222;
}

.dark {
  background-color: var(--light);
}

.dark *,
.light * {
  transition: 0.3s;
}

.light {
  --dark: #222;
  --light: #fafafa;
}

.container {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 3rem;
}

.dark-mode-button {
  position: fixed;
  top: 30%;
  right: 0;
  border: none;
  background-color: var(--dark);
  color: var(--light);
  border: 1px solid var(--light);
  padding: 1rem 2rem;
  transform: rotate(90deg) translateY(15px);
  transform-origin: right;
  z-index: 999999;
}

/* Grid Layout Construction */

.grid {
  display: grid;
  text-align: center;
  grid-gap: 3rem;
}

.grid8 {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.grid6 {
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}

.grid5 {
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}

.grid4 {
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
}

.grid3 {
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
}

.grid2 {
  grid-template-columns: repeat(auto-fit, minmax(36rem, 1fr));
}

.grid8 div,
.grid6 div,
.grid5 div,
.grid4 div,
.grid3 div,
.grid2 div,
.flex div {
  background-color: aqua;
  border: 2px solid var(--dark);
  min-height: 150px;
  vertical-align: center;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.flex8 div {
  flex-grow: 1;
  min-width: 12rem;
}

.flex6 div {
  flex-grow: 1;
  min-width: 12rem;
}

.flex5 div {
  flex-grow: 1;
  min-width: 12rem;
}

@media only screen and (max-width: 760px) {
  .App {
    overflow-x: hidden;
  }
  .dark-mode-button {
    top: 80%;
  }
}
