.header {
  box-shadow: 0 0 10px rgb(207, 207, 207);
  position: sticky;
  background-color: var(--light);
  color: var(--dark);
  top: 0;
  z-index: 999;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  padding: 0 3rem;
  width: 128rem;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.dark .header {
  box-shadow: 0 0 10px rgb(0, 0, 0);
}

.logo {
  width: 24rem;
}

.dark .st0 {
  fill: var(--dark);
}

.st1 {
  fill: #808083;
}

.the-practice-animation {
  animation: left-right 1s;
}

.nav {
  display: flex;
}

.nav a {
  text-decoration: none;
  position: relative;
  font-size: 2rem;
  color: var(--dark);
  padding: 2.5rem 2rem;
}

.nav a:hover::after,
.nav .active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--dark);
  opacity: 0;
  bottom: 0;
  left: 0;
}

.nav a:hover::after,
.nav .active::after {
  opacity: 1;
}

.menu-icon {
  position: absolute;
  right: 4rem;
  top: 2rem;
  display: none;
}

.menu-icon svg {
  font-size: 3rem;
  transition: 0.3s;
  position: absolute;
  background-color: var(--light);
}

#XMLID_27_,
#XMLID_24_ {
  animation: springBoard 4s;
}

@keyframes springBoard {
  0% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(6.5);
  }
}

@keyframes left-right {
  0% {
    transform: translateX(40rem);
  }
  100% {
    transform: translateX(0px);
  }
}

@media only screen and (max-width: 760px) {
  .header {
    max-width: 100%;
    padding: 1rem 0;
  }
  .logo {
    width: 18rem;
  }
  .menu-icon {
    display: block;
  }
  .nav {
    flex-direction: column;
    gap: 0rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 100vh;
    width: 50%;
    padding: 2rem;
    margin-top: 66px;
    transition: 0.3s;
    transform: translateX(100%);
  }
  .dark .nav {
    background-color: var(--light);
    color: var(--dark);
  }
  .active {
    transform: translateX(0%);
    overflow-x: visible;
  }
  .nav a {
    border-bottom: 1px solid rgb(206, 206, 206);
    padding: 2rem 0;
  }
}
